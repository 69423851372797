@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
}

body,
.App {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Navbar {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.min-w-50p {
  min-width: 50%;
}

@layer base {
  h1 h2 {
    font-family: 'Monserrat'
  }

  h3 h4 small {
    font-family: 'Lato'
  }

  h1 {
    font-size: 40px;
    @apply font-semibold;
  }

  h2 {
    font-size: 32px;
    @apply font-bold;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 16px;
  }

  small {
    font-size: 12px;
  }

  /* Table*/

  th {
    @apply px-4 py-2 bg-brandBlueDark;
  }

  table {
    @apply w-full text-sm text-left relative overflow-x-auto rounded-md;
  }

  table thead {
    @apply text-xs uppercase text-white text-center rounded-md;
  }
}

tr {
  @apply odd:bg-brandGray-light;
  @apply even:bg-brandBG;
}

td {
  @apply p-1;
}

/*Scrollbar*/

html::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar {
  background-color: transparent;
  margin-left: 2rem;
  width: 7.5px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid rgba(0, 0, 0, 0);
  /*Transparent color to make the egdes dissapair*/
  background-clip: padding-box;
  /*Slim thumb*/
  border-radius: 999px;
  /*Round egdes*/
  background-color: #e1e0e0;
  /*background-color:#E9E9E9;*/
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-15%);
  }

  to {
    opacity: 0.8;
    transform: translateY(0);
  }
}

.animate-slideDown {
  animation: slideDown 0.2s ease-in-out forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-links {
  animation: slideInFromRight 0.2s ease forwards;
}

.white-img {
  filter: invert(100%);
}

/* @keyframes slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100%);
  }
}
 */
/* .animate-slide {
  animation: 1s infinite slide;
} */

.carousel-container {
  display: flex;
  animation: slideAnimation 20s linear infinite;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(100%); 
  }
  100% {
    transform: translateX(-335%);
  }
}

@keyframes slideDownStagger {
  0% {
    opacity: 0;
    transform: translateY(-1px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slideDownStagger {
  animation: slideDownStagger 0.3s;
}

.css-1ex1afd-MuiTableCell-root {
  padding: 8px !important;
}
